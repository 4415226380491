import { Injectable, inject } from '@angular/core';
import { WatchQueryOptions } from 'apollo-client';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TC_CLAIMS_HEADER } from '../constants/auth-constants';
import { createArea } from '../graphql/facilities/create-area';
import { deleteArea } from '../graphql/facilities/delete-area';
import { getArea } from '../graphql/facilities/get-area';
import { getAvailableAreas } from '../graphql/facilities/get-available-areas';
import { queryForAreas } from '../graphql/facilities/query-for-areas';
import { updateArea } from '../graphql/facilities/update-area';
import { AppsyncService } from './appsync.service';
import { AuthApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class AreaApiService {
  private _api = inject(AppsyncService);
  private _authApi = inject(AuthApiService);


  queryForAreas(
    limit: number,
    page: number,
    query: any,
    sort: any
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: queryForAreas,
          fetchPolicy: 'no-cache',
          variables: {
            limit,
            page,
            query,
            sort,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  getAvailableAreas(): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getAvailableAreas,
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'cache-first',
        };
        return client.query(options);
      })
    );
  }

  getArea(id): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getArea,
          variables: {
            id,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
        };
        return client.query(options);
      })
    );
  }

  deleteArea(id): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: deleteArea,
          variables: { id: id },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  updateArea(id, areaInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: updateArea,
          variables: { id: id, input: areaInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  createArea(areaInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: createArea,
          variables: { input: areaInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }
}
