import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, inject, input, output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AREAS_CONTAINER,
  AREA_DETAIL_CONTAINER,
} from 'src/app/constants/common.constants';
import {
  AREA_TAB_PREFIX,
  NEW_AREA,
} from 'src/app/constants/location-constants';
import { TabService } from 'src/app/core/services/tab.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { Area } from 'src/app/model/locations/area';
import { AreaApiService } from 'src/app/services/area-api.service';
import { AreaBuilderService } from 'src/app/services/area-builder.service';
import { LoadingService } from 'src/app/services/loading.service';
import { RegionApiService } from 'src/app/services/region-api.service';
import { dynamicSort } from 'src/app/core/functions/common-functions';
import { isNullOrUndefined } from 'util';
import { v4 as uuid } from 'uuid';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { TranslateService } from '@ngx-translate/core';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { DeviceService } from 'src/app/services/device.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { ContactListBuilderService } from 'src/app/services/contact-list-builder.service';
import { BaseContainer } from 'src/app/core/containers/base-container';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactBuilderService } from 'src/app/services/contact-builder.service';
import { Contact } from 'src/app/model/contacts/contact';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-area-details-container',
    templateUrl: './area-details-container.component.html',
    styleUrls: ['./area-details-container.component.scss'],
    standalone: false
})
export class AreaDetailsContainerComponent
  extends BaseContainer
  implements OnInit, OnDestroy
{
  protected _translateService = inject(TranslateService);
  private _breadCrumbService = inject(BreadCrumbBuilderService);
  protected _deviceService: DeviceService;
  private _fb = inject(UntypedFormBuilder);
  private _logAndMessage = inject(LogAndMessageService);
  protected _confirmationService = inject(ConfirmationService);
  private _contactListApi = inject(ContactListApiService);
  private _contactListBuilder = inject(ContactListBuilderService);
  private _authApi = inject(AuthApiService);
  private _cdRef = inject(ChangeDetectorRef);
  private _loader = inject(LoadingService);
  protected _regionApi = inject(RegionApiService);
  protected _areaApi = inject(AreaApiService);
  private _areaBuilder = inject(AreaBuilderService);
  protected _contactApi = inject(ContactApiService);
  private _contactBuilder = inject(ContactBuilderService);

  private lId: string;
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set id(id) {
    this.lId = id;
  }
  get id() {
    return this.lId;
  }

  readonly index = input<number>(undefined);

  readonly changesEvent = output<any>();

  areaBreadCrumb: BreadCrumb;
  backToSearchResult: BreadCrumb;
  availableRegions: SelectItem[];
  canCreate = false;
  canEdit = false;
  canDelete = false;
  tabClosingSub: Subscription;
  breadCrumbSub: Subscription;
  valueSub: Subscription;
  area: Area;
  loading = true;
  saving = false;
  form: UntypedFormGroup;
  contactList: ContactList;
  createContactListDialog = false;
  displayDialog = false;
  tempContactList: ContactList;
  actions: SelectItem[] = [];
  selectedAction: any = null;
  isEditing = false;
  tabIndex = 0;
  allContacts: Contact[];
  filteredContacts: SelectItem[];

  constructor() {
    const _deviceService = inject(DeviceService);

    super(_deviceService);
    this._deviceService = _deviceService;

  }

  ngOnInit() {
    this.setupTabClosingSubscription();
    this.setupBreadCrumbSub();

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initialize();
      });
    }
  }

  setupBreadCrumbSub(): void {
    this.breadCrumbSub =
      this._breadCrumbService.breadCrumbSelectedEvent.subscribe(
        (breadCrumb) => {
          this._breadCrumbService.removeBreadCrumb(this.areaBreadCrumb);
          const tab = TabService.getInstance().buildNewTab(
            AREAS_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        }
      );
  }

  initialize() {
    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.LOCATIONS.AREAS.CREATE_AREA,
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([CLAIMS.LOCATIONS.AREAS.EDIT_AREA]);
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.LOCATIONS.AREAS.DELETE_AREA,
    ]);
    var observables = [];

    observables.push(
      this._regionApi.getAvailableRegions(),
      this._contactApi.findContacts()
    );
    if (this.id) {
      observables.push(this._areaApi.getArea(this.id));
    } else {
      // if there's no id, we need an empty area as our base
      this.area = {
        id: null,
        name: null,
        contactListId: null,
        owner: null,
        ownerId: null,
        facilities: [],
        region: null,
        regionId: null,
      };
    }

    forkJoin(observables)
      .pipe(take(1))
      .subscribe((responses: any) => {
        const regionDataClone = { ...responses[0].data };
        this.availableRegions = [
          { label: 'Choose', value: null },
          ...regionDataClone.getAvailableRegions
            .sort(dynamicSort('name', 1))
            .map((r) => ({ label: r.name, value: r.id })),
        ];

        const contactDataClone = { ...responses[1].data };
        this.allContacts = contactDataClone.getContacts
          .map((c) => this._contactBuilder.buildContact(c))
          .sort(dynamicSort('firstName', 1));

        if (responses.length > 2) {
          const areaDataClone = Object.assign({}, responses[2].data);

          this.area = this._areaBuilder.buildArea(areaDataClone.getArea, []);
        }

        this.setupScreen();
      });
  }

  setupScreen() {
    this.findContactList();
    this.buildForm();
    this.updateForm();

    if (this._deviceService.isMobile()) {
      this._translateService.get('LOCATION.SCREEN.AREA').subscribe((label) => {
        this._breadCrumbService.resetAndAddBreadCrumb(
          new BreadCrumb(label, null, true)
        );
        if (this.area) {
          this.areaBreadCrumb = new BreadCrumb(this.area.name, null, false);
        }
        this._breadCrumbService.addBreadCrumb(this.areaBreadCrumb);
      });
    } else {
      if (isNullOrUndefined(this.area.id)) {
        TabService.getInstance().updateActiveTabLabel(NEW_AREA);
      } else {
        TabService.getInstance().updateActiveTabLabel(
          AREA_TAB_PREFIX + this.area.name
        );
      }
    }

    this.updateActionsList();
    this._cdRef.markForCheck();
    this.loading = false;
  }

  findContactList() {
    if (this.area.contactListId) {
      // get Contact List
      this._contactListApi
        .getContactList(this.area.contactListId)
        .pipe(take(1))
        .subscribe(({ data }) => {
          const clone = Object.assign({}, data);
          this.contactList = this._contactListBuilder.buildContactList(
            clone.getContactList
          );
          this.contactList = { ...this.contactList };
          this._cdRef.detectChanges();
          this._cdRef.markForCheck();
        });
    } else {
      console.log('No contact list to find.');
    }
  }

  findOwner() {
    if (this.area.ownerId) {
      return this.allContacts
        .filter((c) => c.id == this.area.ownerId)
        .map((c) => ({ label: c.firstName + ' ' + c.lastName, value: c.id }))
        .shift();
    }
    return null;
  }

  buildForm() {
    this.form = this._fb.group({
      id: null,
      name: [null, Validators.required],
      contactListId: null,
      owner: null,
      region: [null, Validators.required],
      facilities: null,
      updater: [{ value: null, disabled: true }],
      version: null,
    });

    this.valueSub = this.form.valueChanges.subscribe((changes) => {
      if (this.form.dirty) {
        this.changesEvent.emit({
          isDirty: true,
          index: this.index(),
          id: this.id,
        });
      }
    });
  }

  updateForm() {
    if (!isNullOrUndefined(this.area)) {
      this.form.patchValue({
        id: this.area.id,
        name: this.area.name,
        contactListId: this.area.contactListId,
        owner: this.findOwner(),
        region: this.area.regionId,
        updater: this.area.updater,
        version: this.area.version,
      });

      if (isNullOrUndefined(this.area.id)) {
        this.isEditing = true;
      }

      if (!this.canEdit && !this.canCreate) {
        this.form.disable();
      } else if (this.area.id && this.isEditing === false) {
        this.form.disable();
      }
    }
  }

  updateActionsList() {
    if (this.canEdit || this.canDelete) {
      const selectAction$ = this._translateService.get(
        'COMMON.LABEL.SELECT_ACTION'
      );
      const removeList$ = this._translateService.get(
        'LOCATION.LABEL.BUTTONS.REMOVE_CONTACT_LIST'
      );
      const delete$ = this._translateService.get('COMMON.LABEL.BUTTONS.DELETE');

      let select,
        removeList,
        del = null;
      forkJoin([selectAction$, removeList$, delete$])
        .pipe(take(1))
        .subscribe((messages) => {
          select = messages[0];
          removeList = messages[1];
          del = messages[2];

          this.actions = [
            { label: select, value: null },
            { label: removeList, value: 'remove-list' },
          ];

          if (this.canDelete) {
            this.actions.push({ label: del, value: 'delete-area' });
          }

          // default actions here: Send to Day Crew, etc.
          this.actions = [...this.actions];
        });
    }
  }

  actionEvent($event, object?: any) {
    if ($event.value) {
      this.selectedAction = null;
      if ($event.value === 'remove-list') {
        this.clearContactList();
      } else if ($event.value === 'delete-area') {
        let confHeader = null,
          ok = null,
          cancel = null,
          message = null;
        const confHeader$ = this._translateService.get(
          'LOCATION.MESSAGES.HEADERS.DELETE_AREA'
        );
        const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
        const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
        const message$ = this._translateService.get(
          'LOCATION.MESSAGES.CONFIRMATION.DELETE_AREA',
          { name: this.area.name }
        );

        forkJoin([confHeader$, ok$, cancel$, message$]).subscribe(
          (messages) => {
            confHeader = messages[0];
            ok = messages[1];
            cancel = messages[2];
            message = messages[3];
          }
        );

        this._confirmationService.confirm({
          header: confHeader,
          icon: 'fa fa-question-circle',
          acceptVisible: true,
          rejectVisible: true,
          acceptLabel: ok,
          rejectLabel: cancel,
          message: message,
          accept: () => {
            this.saving = true;
            if (
              !isNullOrUndefined(this.area) &&
              !isNullOrUndefined(this.area.id)
            ) {
              this._areaApi
                .deleteArea(this.area.id)
                .pipe(take(1))
                .subscribe(
                  (result) => {
                    this.saving = false;
                    this.displayDialog = false;
                  },
                  (error) => {
                    console.log('Error deleting area', error);
                  },
                  () => {
                    const index = TabService.getInstance().getActiveIndex();
                    TabService.getInstance().closeTab(index);
                  }
                );
            }
          },
          reject: () => {},
        });
      }

      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }
    }
    this._cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.filteredContacts = null;
    this.area = null;
    this.contactList = null;
    if (this.breadCrumbSub) {
      this.breadCrumbSub.unsubscribe();
    }
  }

  saveArea(fromAction = false) {
    if (this.form.valid || fromAction === true) {
      const areaForm = Object.assign({}, this.form.getRawValue());
      const area = Object.assign(
        {},
        {
          id: areaForm.id,
          name: areaForm.name,
          ownerId: areaForm.owner?.value,
          regionId: areaForm.region,
          contactListId: this.tempContactList
            ? this.tempContactList.id
            : this.area.contactListId,
        }
      );

      if (!areaForm.id) {
        this.createArea(area);
      } else {
        this.updateArea(area);
      }
    } else {
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'LOCATION.MESSAGES.HEADERS.SAVE_AREA',
        bodyKey: 'LOCATION.MESSAGES.ERROR.SAVING_AREA_ERROR',
      });
    }
  }

  createArea(area) {
    if (!this.canCreate) {
      return;
    }
    this.saving = true;

    area.id = uuid();
    area.updater = null;
    area.updatedAt = null;
    area.createdAt = null;
    const areaInput = Object.assign(
      {},
      {
        id: area.id,
        name: area.name,
        ownerId: area.ownerId,
        regionId: area.regionId,
        contactListId: area.contactListId,
      }
    );

    this._areaApi
      .createArea(areaInput)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
            headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
          });
          const clone = Object.assign({}, data);
          this.area = Object.assign(
            {},
            this._areaBuilder.buildArea(Object.assign({}, clone.createArea), [])
          );
          this.saving = false;
          this.findContactList();
          this.updateForm();
          this.form.markAsPristine();
          this._cdRef.markForCheck();
        },
        (error) => {
          console.log(error);
          this.saving = false;
          this.setEditing(false);
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'LOCATION.MESSAGES.HEADERS.SAVE_AREA',
            bodyKey: 'LOCATION.MESSAGES.ERROR.SAVING_AREA_ERROR',
          });
        },
        () => {
          if (!this._deviceService.isMobile()) {
            const oldTab = TabService.getInstance().getActiveTab();
            const tab = TabService.getInstance().buildNewTab(
              AREA_DETAIL_CONTAINER,
              true,
              null,
              this.area.id
            );
            TabService.getInstance().replaceTab(oldTab, tab);
            this.changesEvent.emit({
              isDirty: false,
              index: this.index(),
              id: this.id,
            });
          }
        }
      );
  }

  updateArea(area) {
    if (!this.canEdit) {
      return;
    }

    this.saving = true;

    const areaInput = Object.assign(
      {},
      {
        id: area.id,
        name: area.name,
        ownerId: area.ownerId,
        regionId: area.regionId,
        contactListId: area.contactListId,
      }
    );

    // If the contactlistid is pristine, that means that we haven't
    // removed it or changed it, and so it won't come in from getDirtyValues
    // but our lambda will remove the list if we don't send it back
    // so we need to re-send it every time we make other changes
    // to the list
    if (this.form.controls['contactListId'].pristine) {
      areaInput['contactListId'] = this.form.controls['contactListId'].value;
    }

    if (!isNullOrUndefined(this.area)) {
      const update = this._areaBuilder.buildUpdateStatement(
        this.area,
        areaInput
      );

      this._areaApi
        .updateArea(this.area.id, update)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
              headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
            });
            const clone = Object.assign({}, data);
            this.area = Object.assign(
              {},
              this._areaBuilder.buildArea(clone.updateArea, [])
            );
            if (areaInput['contactListId']) {
              this.findContactList();
            }
            this._cdRef.markForCheck();
            this.updateForm();
            this.form.markAsPristine();
            this.saving = false;
            this.setEditing(false);
          },
          (error) => {
            this.saving = false;
            this.setEditing(false);
            this._logAndMessage.translateToErrorMessage({
              headerKey: 'LOCATION.MESSAGES.HEADERS.SAVE_AREA',
              bodyKey: 'LOCATION.MESSAGES.ERROR.SAVING_AREA_ERROR',
            });
          },
          () => {
            this.changesEvent.emit({
              isDirty: false,
              index: this.index(),
              id: this.id,
            });
          }
        );
    }
  }

  openCreateContactList() {
    this.createContactListDialog = true;
  }

  setTempList(list) {
    if (this.canEdit === true) {
      this.setEditing(true);
      this.tempContactList = list;
      this.form.patchValue({
        contactListId: !isNullOrUndefined(this.tempContactList)
          ? this.tempContactList.id
          : null,
      });
      this.form.controls['contactListId'].markAsDirty();
      this.createContactListDialog = false;
    }
  }

  clearContactList() {
    if (this.canEdit === true) {
      this.setEditing(true);
      this.form.patchValue({
        ContactListId: null,
      });
      this.form.controls['contactListId'].markAsDirty();
      this.area.contactListId = null;
      this.contactList = null;
      this.tempContactList = null;
    }
  }

  setEditing(editing: boolean) {
    if (!this.canCreate && !this.canEdit) {
      this._logAndMessage.translateToWarnMessage({
        bodyKey: 'COMMON.MESSAGES.WARN.ADMIN_RIGHTS',
        headerKey: 'COMMON.MESSAGES.HEADERS.AUTHORIZATION_MESSAGE',
      });
      return;
    }

    if (!editing) {
      this.isEditing = false;
      this.form.disable();
    } else {
      this.isEditing = true;
      this.form.enable();
      // always disabled
      this.form.controls['updater'].disable();
    }
  }

  tabChanged($event): void {
    if (!isNullOrUndefined($event)) {
      this.tabIndex = $event.index;
    }
  }

  setupTabClosingSubscription(): void {
    this.tabClosingSub = TabService.getInstance().attemptCloseEvent.subscribe(
      (closingTab) => {
        if (!isNullOrUndefined(this.id)) {
          if (this.id === closingTab.id && (!this.form || !this.form.dirty)) {
            TabService.getInstance().closeTab(closingTab.index);
          } else if (
            this.id === closingTab.id &&
            this.form &&
            this.form.dirty
          ) {
            const unsaved$ = this._translateService.get(
              'COMMON.MESSAGES.CONFIRMATION.UNSAVED_CHANGES'
            );
            const yes$ = this._translateService.get(
              'COMMON.LABEL.BUTTONS.LEAVE'
            );
            const no$ = this._translateService.get(
              'COMMON.LABEL.BUTTONS.CANCEL'
            );
            const header$ = this._translateService.get(
              'COMMON.LABEL.HEADERS.LEAVE_PAGE'
            );
            forkJoin(unsaved$, yes$, no$, header$).subscribe((messages) => {
              this._confirmationService.confirm({
                header: messages[3],
                icon: 'fa fa-question-circle',
                acceptVisible: true,
                rejectVisible: true,
                acceptLabel: messages[1],
                rejectLabel: messages[2],
                message: messages[0],
                accept: () => {
                  if (!isNullOrUndefined(closingTab)) {
                    TabService.getInstance().closeTab(closingTab.index);
                  }
                },
                reject: () => {
                  TabService.getInstance().setActiveTab(closingTab.index);
                  TabService.getInstance().revertTabChangeEvent.emit();
                },
              });
            });
          }
        }
      }
    );
  }

  contactSearch($event) {
    if ($event.query) {
      this.filteredContacts = this.allContacts
        .filter((c) =>
          (c.firstName + ' ' + c.lastName)
            .toLowerCase()
            .startsWith($event.query.toLowerCase())
        )
        .filter((c: Contact) => c.inactiveAdAccount === false)
        .map((c) => ({ label: c.firstName + ' ' + c.lastName, value: c.id }));
    } else {
      this.filteredContacts = this.allContacts.map((c) => ({
        label: c.firstName + ' ' + c.lastName,
        value: c.id,
      }));
    }
  }
}
