<div id="region-details">
  <app-progress-spinner [enabled]="!region"></app-progress-spinner>
  <p-blockUI [blocked]="saving === true || loading === true"></p-blockUI>
  @if (region) {
    <div>
      @if (form) {
        <form [formGroup]="form" id="region-create-form">
          <div id="region-action-buttons-form">
            <div class="button-header">
              <div>
                <app-progress-spinner [enabled]="saving"></app-progress-spinner>
              </div>
              @if (
                region?.id &&
                actions.length > 0 &&
                (canEdit || canDelete || canCreate)
                ) {
                <div
                  class="input"
                  >
                  <span class="label">{{ "COMMON.LABEL.ACTIONS" | translate }}:</span>
                  <span class="align-left">
                    <p-dropdown
                      [options]="actions"
                      (onChange)="actionEvent($event, dd)"
                      [(ngModel)]="selectedAction"
                      [ngModelOptions]="{ standalone: true }"
                      styleClass="action-drop-down"
                      #dd
                    ></p-dropdown>
                  </span>
                </div>
              }
              @if (
                (canEdit || canCreate) &&
                isEditing === true &&
                !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])
                ) {
                <button
                  pButton
                  label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                  icon="fa fa-fw fa-save"
                  (click)="saveRegion()"
                  [disabled]="!this.form.valid || saving"
                ></button>
              }
              @if (
                canEdit &&
                isEditing === false &&
                !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])
                ) {
                <button
                  pButton
                  label="{{ 'COMMON.LABEL.BUTTONS.EDIT' | translate }}"
                  icon="fa fa-fw fa-save"
                  (click)="setEditing(true)"
                ></button>
              }
            </div>
            <hr id="region-scroll-divider" />
            <p-scrollPanel styleClass="region-scroll-panel">
              <div>
                <div class="row">
                  <div class="col row-item">
                    <span class="label"
                      >{{ "LOCATION.LABEL.REGION_NAME" | translate }}:</span
                      >
                      <span class="row-input">
                        <input
                          type="text"
                          pInputText
                          class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                          formControlName="name"
                          />
                        </span>
                      </div>
                      <div class="col row-item">
                        <span class="label"
                          >{{ "COMMON.LABEL.OWNER" | translate }}:</span
                          >
                          <span class="row-input">
                            <p-autoComplete
                              [suggestions]="filteredContacts"
                              (completeMethod)="contactSearch($event)"
                              formControlName="owner"
                              [dropdown]="true"
                              field="label"
                              appendTo="body"
                            ></p-autoComplete>
                          </span>
                        </div>
                        <div class="col row-item">
                          <span class="label"
                            >{{ "COMMON.LABEL.UPDATER" | translate }}:</span
                            >
                            <span class="row-input">
                              <input
                                type="text"
                                pInputText
                                class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                                formControlName="updater"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div id="region-tab-view">
                          <p-tabView [activeIndex]="tabIndex" (onChange)="tabChanged($event)">
                            <p-tabPanel header="CONTACT LIST" class="tab-panel">
                              @if (contactList) {
                                <div>
                                  <app-contact-list-simple-view-container
                                    [contactList]="contactList"
                                    [embedded]="true"
                                    >
                                  </app-contact-list-simple-view-container>
                                </div>
                              }
                              @if (!contactList && (canEdit || canCreate)) {
                                <div>
                                  @if (!tempContactList) {
                                    <div>
                                      <div>
                                        Select a contact list from below{{
                                        canCreateContactList ? " or create a new list." : "."
                                        }}
                                      </div>
                                      <div>
                                        @if (canCreateContactList) {
                                          <button
                                            pButton
                                            label="{{ 'COMMON.LABEL.BUTTONS.CREATE' | translate }}"
                                            icon="fa fa-fw fa-plus"
                                            (click)="openCreateContactList()"
                                            [disabled]="saving"
                                          ></button>
                                        }
                                      </div>
                                      <div>
                                        <app-contact-list-search-container
                                          [embedded]="true"
                                          (selectedListEvent)="setTempList($event.data)"
                                          >
                                        </app-contact-list-search-container>
                                      </div>
                                    </div>
                                  }
                                  @if (tempContactList) {
                                    <div>
                                      List Associated will be saved when region is saved.
                                    </div>
                                  }
                                </div>
                              }
                              @if (!contactList && !canEdit && !canCreate) {
                                <div>
                                  No contact list is associated with this Region.
                                </div>
                              }
                            </p-tabPanel>
                            @if (region?.id) {
                              <p-tabPanel header="AREAS" class="tab-panel">
                                @if (region?.areas?.length) {
                                  <div>
                                    @for (area of region.areas; track area) {
                                      <div class="area-box">
                                        <p-panel [showHeader]="false" class="area-card">
                                          <div class="area-card">
                                            <div class="area-rows">
                                              <div class="section">{{ area.name }}</div>
                                            </div>
                                          </div>
                                        </p-panel>
                                      </div>
                                    }
                                  </div>
                                }
                                @if (!region?.areas?.length) {
                                  <div>
                                    No areas associated with this Region.
                                  </div>
                                }
                              </p-tabPanel>
                            }
                          </p-tabView>
                        </div>
                      </p-scrollPanel>
                    </div>
                  </form>
                }
              </div>
            }

            <p-dialog
              header="Create Contact List"
              [(visible)]="createContactListDialog"
              showEffect="fade"
              [modal]="true"
              [transitionOptions]="'0ms'"
              [contentStyle]="{ 'max-height': '75vh' }"
              >
              <app-contact-list-create-container
                [embedded]="true"
                (newContactCreatedEvent)="setTempList($event)"
                >
              </app-contact-list-create-container>
            </p-dialog>
          </div>
