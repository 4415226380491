import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { forkJoin, interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { VIEW_AUDIT } from 'src/app/constants/action-constants';
import {
  FACILITY_DETAIL_CONTAINER,
  MONLOG_DETAILS_CONTAINER,
  FACILITY_SEARCH_CONTAINER,
} from 'src/app/constants/common.constants';
import {
  FACILITY_TAB_PREFIX,
  NEW_FACILITY,
} from 'src/app/constants/location-constants';
import {
  A_AND_E_LOG_CATEGORY,
  M_AND_R_LOG_CATEGORY,
  M_AND_R_MAINTENANCE,
} from 'src/app/constants/monlog-constants';
import { DetailsContainer } from 'src/app/core/containers/details-container';
import {
  buildAuditHistory,
  dynamicSort,
  getDirtyValues,
} from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { Contact } from 'src/app/model/contacts/contact';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { Facility } from 'src/app/model/locations/facility';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactBuilderService } from 'src/app/services/contact-builder.service';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { ContactListBuilderService } from 'src/app/services/contact-list-builder.service';
import { DeviceService } from 'src/app/services/device.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { MonlogsBuilderService } from 'src/app/services/monlogs-builder.service';
import { MonLogsTableService } from 'src/app/services/monlogs-table.service';
import { v4 as uuid } from 'uuid';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { MonlogsApiService } from 'src/app/services/monlogs-api.service';
import { AreaApiService } from 'src/app/services/area-api.service';
import { RegionApiService } from 'src/app/services/region-api.service';
import { AreaBuilderService } from 'src/app/services/area-builder.service';
import { RegionBuilderService } from 'src/app/services/region-builder.service';
import moment from 'moment';
import { ContactListSimpleViewContainerComponent } from 'src/app/contacts/containers/contact-list-simple-view/contact-list-simple-view-container.component';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { isNullOrUndefined, sortByProperty } from 'src/app/utils/utils';
import { SapApiService } from 'src/app/services/sap-api.service';
import { ArchivedEntryStatus } from '../../components/facility-notepad/facility-notepad.component';
import { ConfigItem } from 'src/app/model/common/config-item';
import { timezones } from 'src/app/constants/timezones';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-facility-details-container',
    templateUrl: './facility-details-container.component.html',
    styleUrls: ['./facility-details-container.component.scss'],
    standalone: false
})
export class FacilityDetailsContainerComponent
  extends DetailsContainer
  implements OnInit, OnDestroy
{
  protected _translateService: TranslateService;
  private _breadCrumbService = inject(BreadCrumbBuilderService);
  protected _deviceService: DeviceService;
  private _fb = inject(UntypedFormBuilder);
  private _logAndMessage = inject(LogAndMessageService);
  protected _confirmationService: ConfirmationService;
  private _contactListApi = inject(ContactListApiService);
  private _contactListBuilder = inject(ContactListBuilderService);
  protected _contactApi: ContactApiService;
  private _authApi = inject(AuthApiService);
  private _contactBuilder = inject(ContactBuilderService);
  protected _locationApi: LocationApiService;
  private _facilityBuilder = inject(FacilityBuilderService);
  protected _facilityApi: FacilityApiService;
  private _cdRef = inject(ChangeDetectorRef);
  private _monLogBuilder = inject(MonlogsBuilderService);
  private _monLogTableService = inject(MonLogsTableService);
  protected _facilityTableService: FacilityTableService;
  private _monLogApi = inject(MonlogsApiService);
  private _loader = inject(LoadingService);
  protected _areaApi = inject(AreaApiService);
  protected _regionApi = inject(RegionApiService);
  private _areaBuilder = inject(AreaBuilderService);
  private _regionBuilder = inject(RegionBuilderService);
  private _sapApi = inject(SapApiService);

  ArchivedEntryStatus = ArchivedEntryStatus;

  timeZones: { label: string; value: string }[];
  timezone: string;
  localTimeInterval: Subscription;
  DirtyStatus = DirtyStatus;
  facility: Facility;
  contactList: ContactList;
  managementContactList: ContactList;
  contactListsForDropdowns: ConfigItem[];
  breadCrumbSub: Subscription;
  saving = false;
  addLocationToFacForm: UntypedFormGroup;
  displayDialog = false;
  unPersistedNewFacilityLocations: any[];
  unPersistedExistingFacilityLocations: any[];

  regions: any[];
  filteredRegions: any[];
  areas: any[];
  filteredAreas: any[];

  contacts: any[];
  filteredContacts: any[];
  facilityBreadCrumb: BreadCrumb;
  backToSearchResult: BreadCrumb;
  availableCompanies: SelectItem[];
  availableDistricts: SelectItem[];
  availableBusinessUnits: SelectItem[];
  monLogTypes: any[];
  canCreate = false;
  canEdit = false;
  canCreateMonLogs = false;
  canAddContactListToFacility = false;
  canRemoveContactListFromFacility = false;
  canSyncSap = false;
  actions: SelectItem[] = [];
  types: SelectItem[];
  selectedAction: any = null;
  isEditting = false;
  locations: any[];
  tabIndex = 0;
  events: any[] = [];

  displayLocationsDialog: boolean = false;

  facilityStates: any[] = [];
  facilityCounties: any[] = [];
  facilityTownships: any[] = [];
  locationDialogType = 'State';

  localTime: string;

  constructor() {
    const _translateService = inject(TranslateService);
    const _deviceService = inject(DeviceService);
    const _confirmationService = inject(ConfirmationService);
    const _contactApi = inject(ContactApiService);
    const _locationApi = inject(LocationApiService);
    const _facilityApi = inject(FacilityApiService);
    const _facilityTableService = inject(FacilityTableService);

    super(
      _translateService,
      _deviceService,
      _confirmationService,
      _locationApi,
      _contactApi,
      _facilityTableService,
      _facilityApi
    );
    this._translateService = _translateService;
    this._deviceService = _deviceService;
    this._confirmationService = _confirmationService;
    this._contactApi = _contactApi;
    this._locationApi = _locationApi;
    this._facilityApi = _facilityApi;
    this._facilityTableService = _facilityTableService;

  }

  ngOnInit() {
    this.setupTabClosingSubscription();
    this.setupBreadCrumbSub();
    this.addLocationToFacForm = this._fb.group({
      state: [null, Validators.required],
      county: [null, Validators.required],
      township: [null, Validators.required],
      locationId: [null],
    });

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initialize();
      });
    }

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.LOCATIONS.FACILITIES.CREATE_FACILITIES,
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.LOCATIONS.FACILITIES.EDIT_FACILITIES,
    ]);
    this.canCreateMonLogs = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.MONITORING_CENTER.MON_LOGS.CREATE_MON_LOGS,
    ]);
    this.canAddContactListToFacility =
      this._authApi.doesUserHaveAllClaimsFromList([CLAIMS.LOCATIONS.FACILITIES.ADD_CONTACT_LIST_TO_FACILITY]);
    this.canRemoveContactListFromFacility =
      this._authApi.doesUserHaveAllClaimsFromList([
        CLAIMS.LOCATIONS.FACILITIES.REMOVE_CONTACT_LIST_FROM_FACILITY,
      ]);
    this.canSyncSap = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.LOCATIONS.FACILITIES.SYNC_SAP_FOR_FACILITY,
    ]);
  }

  setupBreadCrumbSub(): void {
    this.breadCrumbSub =
      this._breadCrumbService.breadCrumbSelectedEvent.subscribe(
        (breadCrumb) => {
          this._breadCrumbService.removeBreadCrumb(this.facilityBreadCrumb);
          const tab = TabService.getInstance().buildNewTab(
            FACILITY_SEARCH_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        }
      );
  }

  initialize() {
    this.loadContactLists();
    this.loadAllDropDownData();
    this.loadMonLogTypes();
    this.getFacilityFromId();

    this._facilityApi
      .loadAvailableFacilityTypes()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = { ...data };
        this.types = [
          { label: 'Choose', value: null },
          ...clone.getFacilityTypes
            .sort(dynamicSort('name', 1))
            .map((t) => ({ label: t.name, value: t.id })),
        ];
      });

    this.availableBusinessUnits = this._authApi
      .getUserBusinessUnits()
      .map((bu) => {
        return { label: bu.name, value: bu.id };
      });

    const refreshInterval = interval(1000);
    this.localTimeInterval = refreshInterval.subscribe(() => {
      if (this.timezone) {
        this.localTime = moment()
          .tz(this.timezone)
          .format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.localTime = 'No Time zone Selected';
      }
    });
  }

  loadAllDropDownData() {
    // load details-container dropdowns
    this.loadDropDownData();

    // load extra dropdowns that aren't needed on other details views
    var observables = [];

    observables.push(
      this._contactApi.findContacts(),
      this._regionApi.getAvailableRegions(),
      this._areaApi.getAvailableAreas()
    );

    forkJoin(observables)
      .pipe(take(1))
      .subscribe((responses: any) => {
        const contactDataClone = { ...responses[0].data };
        this.contacts = contactDataClone.getContacts
          .map((c) => this._contactBuilder.buildContact(c))
          .sort(dynamicSort('firstName', 1));

        const regionDataClone = { ...responses[1].data };
        this.regions = regionDataClone.getAvailableRegions
          .map((r) => this._regionBuilder.buildRegion(r, []))
          .sort(dynamicSort('name', 1));

        const areaDataClone = { ...responses[2].data };
        this.areas = areaDataClone.getAvailableAreas
          .map((a) => this._areaBuilder.buildArea(a, []))
          .sort(dynamicSort('name', 1));

        // update the form if necessary
        this.updateForm();
      });
  }

  getFacilityFromId() {
    const id = this.id;
    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.LOCATIONS.FACILITIES.CREATE_FACILITIES,
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.LOCATIONS.FACILITIES.EDIT_FACILITIES,
    ]);
    this.canCreateMonLogs = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.MONITORING_CENTER.MON_LOGS.CREATE_MON_LOGS,
    ]);
    this.unPersistedNewFacilityLocations = [];
    this.unPersistedExistingFacilityLocations = [];
    if (id) {
      this._facilityApi
        .getFacility(id)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            const clone = Object.assign({}, data);
            this.facility = this._facilityBuilder.buildFacility(
              clone.getFacility,
              []
            );
            if (this.facility.timezone) {
              this.timezone = this.facility.timezone;
            }

            if (this.facility.locations && this.facility.locations.length > 0) {
              this.facilityStates = Array.from(
                new Set(this.facility.locations.map((loc) => loc.state))
              );
              this.facilityCounties = Array.from(
                new Map(
                  this.facility.locations.map((loc) => [
                    loc.county,
                    {
                      state: loc.state,
                      county: loc.county,
                      __typename: 'County',
                    },
                  ])
                ).values()
              );
              this.facilityTownships = this.facility.locations.map((loc) => ({
                id: loc.id,
                state: loc.state,
                county: loc.county,
                township: loc.township,
                __typename: 'Township',
              }));
            }

            this.doCheckComplete = false;
            this.setupScreen();
          },
          (error) => {
            console.error(error);
          },
          () => {
            //this.setupFacilitySubscription();
          }
        );
    } else {
      // if there's no id, we need an empty facility as our base
      this.facility = {
        id: null,
        ContactListId: null,
        managementContactListId: null,
        aor: null,
        aorId: null,
        company: null,
        companyId: null,
        county: null,
        facilityName: null,
        facilityType: null,
        facilityUid: null,
        locations: [],
        businessUnit: null,
        notes: null,
        state: null,
        township: null,
        address: null,
        gps: null,
        phone: null,
        altPhone: null,
        scadaId: null,
        owner: null,
        ownerId: null,
        area: null,
        areaId: null,
        flocNumber: null,
        timezone: 'America/New_York', // default for new facilities,
        NotepadEntries: [],
      };
      this.timezone = this.facility.timezone;
      this.setupScreen();
    }
  }

  setupScreen() {
    this.findContactList();
    this.buildForm();
    this.loadTimeZones();
    this.updateForm();

    if (this._deviceService.isMobile()) {
      this._translateService
        .get('LOCATION.SCREEN.FACILITY')
        .subscribe((label) => {
          this._breadCrumbService.resetAndAddBreadCrumb(
            new BreadCrumb(label, null, true)
          );
          if (this.facility) {
            this.facilityBreadCrumb = new BreadCrumb(
              this.facility.facilityName,
              null,
              false
            );
          }
          this._breadCrumbService.addBreadCrumb(this.facilityBreadCrumb);
        });
    } else {
      if (isNullOrUndefined(this.facility.id)) {
        TabService.getInstance().updateActiveTabLabel(NEW_FACILITY);
      } else {
        TabService.getInstance().updateActiveTabLabel(
          FACILITY_TAB_PREFIX + this.facility.facilityName
        );
      }
    }

    this.updateActionsList();
    this._cdRef.markForCheck();
    this.loading = false;
  }

  updateActionsList() {
    const selectAction$ = this._translateService.get(
      'MONLOG.LABEL.SELECT_ACTION'
    );
    const delete$ = this._translateService.get('COMMON.LABEL.BUTTONS.DELETE');
    const newCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_CALL'
    );
    const newMRCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_MR_CALL'
    );
    const newAECall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_AE_CALL'
    );
    const newInternalCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_CONTROLLER_CALL'
    );
    const viewAudit$ = this._translateService.get(
      'COMMON.LABEL.VIEW_AUDIT_INFO'
    );
    const syncSap$ = this._translateService.get('COMMON.LABEL.SYNC_SAP');

    let select,
      del,
      newCall,
      newMRCall,
      newAECall,
      newInternalCall,
      audit,
      syncSap = null;
    forkJoin([
      selectAction$,
      delete$,
      newCall$,
      newMRCall$,
      newAECall$,
      newInternalCall$,
      viewAudit$,
      syncSap$,
    ])
      .pipe(take(1))
      .subscribe((messages) => {
        select = messages[0];
        del = messages[1];
        newCall = messages[2];
        newMRCall = messages[3];
        newAECall = messages[4];
        newInternalCall = messages[5];
        audit = messages[6];
        syncSap = messages[7];

        this.actions = [{ label: select, value: null }];

        if (this.canEdit) {
          this.actions.push({ label: del, value: 'delete-facility' });
        }

        if (this.canCreateMonLogs) {
          this.actions.push({ label: newCall, value: 'new-call' });
          this.actions.push({ label: newMRCall, value: 'new-mr-call' });
          this.actions.push({ label: newAECall, value: 'new-ae-call' });
          this.actions.push({
            label: newInternalCall,
            value: 'new-internal-call',
          });
        }

        if (this.canSyncSap) {
          this.actions.push({ label: syncSap, value: 'sync-sap' });
        }

        this.actions.push({ label: audit, value: VIEW_AUDIT });

        // default actions here: Send to Day Crew, etc.
        this.actions = [...this.actions];
      });
  }

  actionEvent($event, object?: any) {
    if ($event.value) {
      this.selectedAction = null;
      if ($event.value === 'new-call') {
        this.createLog('N');
      } else if ($event.value === 'new-mr-call') {
        this.createLog('MR');
      } else if ($event.value === 'new-ae-call') {
        this.createLog('AE');
      } else if ($event.value === 'new-internal-call') {
        this.createLog('I');
      } else if ($event.value === 'delete-facility') {
        if (!this.canEdit) {
          return;
        }
        let confHeader = null,
          ok = null,
          cancel = null,
          message = null;
        const confHeader$ = this._translateService.get(
          'LOCATION.MESSAGES.HEADERS.DELETE_FACILITY'
        );
        const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
        const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
        const message$ = this._translateService.get(
          'LOCATION.MESSAGES.CONFIRMATION.DELETE_FACILITY',
          { name: this.facility.facilityName }
        );

        forkJoin([confHeader$, ok$, cancel$, message$]).subscribe(
          (messages) => {
            confHeader = messages[0];
            ok = messages[1];
            cancel = messages[2];
            message = messages[3];
          }
        );
        this._confirmationService.confirm({
          header: confHeader,
          icon: 'fa fa-question-circle',
          acceptVisible: true,
          rejectVisible: true,
          acceptLabel: ok,
          rejectLabel: cancel,
          message: message,
          accept: () => {
            this.saving = true;
            if (
              !isNullOrUndefined(this.facility) &&
              !isNullOrUndefined(this.facility.id)
            ) {
              // Existing group so delete it
              this._facilityApi
                .deleteFacility(this.facility.id)
                .pipe(take(1))
                .subscribe(
                  (result) => {
                    this.saving = false;
                    this.displayDialog = false;
                  },
                  (error) => {
                    console.log('Error deleting facility', error);
                  },
                  () => {
                    const index = TabService.getInstance().getActiveIndex();
                    TabService.getInstance().closeTab(index);
                  }
                );
            }
          },
          reject: () => {},
        });
      } else if ($event.value === VIEW_AUDIT) {
        if (this.facility) {
          this.auditLoading = true;
          this.displayAuditDialog = true;
          this.loading = true;
          this._facilityApi
            .findFacilityHistory(this.facility.id)
            .pipe(take(1))
            .subscribe(
              ({ data }) => {
                const clone = Object.assign({}, data);
                this.history = [...buildAuditHistory(clone.getFacilityHistory)];
                this.auditLoading = false;
                this.loading = false;
                this._cdRef.markForCheck();
              },
              (error) => {
                this.auditLoading = false;
                this.loading = false;
              }
            );
        }
      } else if ($event.value === 'sync-sap') {
        this.syncSapOutagesForFacility();
      }

      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }
    }
    this._cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.filteredContacts = null;
    this.filteredCounties = null;
    this.filteredStates = null;
    this.filteredTownships = null;
    this.states = null;
    this.counties = null;
    this.townships = null;
    this.facility = null;
    this.contactList = null;
    if (this.breadCrumbSub) {
      this.breadCrumbSub.unsubscribe();
    }
    if (this.localTimeInterval) {
      this.localTimeInterval.unsubscribe();
    }
  }

  buildForm() {
    this.form = this._fb.group({
      facilityName: [null, Validators.required],
      facilityUid: [null, Validators.required],
      facilityType: [null, Validators.required],
      address: null,
      gps: null,
      phone: null,
      altPhone: null,
      scadaId: null,
      aor: [null, Validators.required],
      aorName: [null],
      company: [null, Validators.required],
      companyName: [null],
      businessUnit: [null, Validators.required],
      notes: null,
      updater: [{ value: null, disabled: true }],
      locationId: null,
      id: null,
      version: null,
      ContactListId: null,
      managementContactListId: null,
      owner: null,
      area: null,
      region: null,
      flocNumber: null,
      timezone: null,
    });

    this.valueSub = this.form.valueChanges.subscribe((changes) => {
      if (this.form.dirty) {
        this.changesEvent.emit({
          isDirty: true,
          index: this.index(),
          id: this.id,
        });
      }
    });
  }

  updateForm() {
    if (!isNullOrUndefined(this.facility)) {
      let selectedBusinessUnit = this.facility.businessUnit?.id;
      this.form.patchValue({
        facilityName: this.facility.facilityName,
        facilityUid: this.facility.facilityUid,
        facilityType: this.facility.FacilityType
          ? this.facility.FacilityType.id
          : null,
        address: this.facility.address,
        aor: this.facility.aorId,
        phone: this.facility.phone,
        altPhone: this.facility.altPhone,
        gps: this.facility.gps,
        scadaId: this.facility.scadaId,
        aorName: this.facility.aor,
        company: this.facility.companyId,
        companyName: this.facility.company,
        businessUnit: selectedBusinessUnit,
        notes: this.facility.notes,
        updater: this.facility.updater,
        id: this.facility.id,
        version: this.facility.version,
        ContactListId: this.facility.ContactListId,
        managementContactListId: this.facility.managementContactListId,
        owner: this.findOwner(),
        area: this.findArea(),
        region: this.findRegion(),
        flocNumber: this.facility.flocNumber,
        timezone: this.timeZones.find(
          (tz) => tz.value === this.facility.timezone
        ),
      });

      if (isNullOrUndefined(this.facility.id)) {
        this.isEditting = true;
      }

      if (!this.canEdit && !this.canCreate) {
        this.form.disable();
      } else if (this.facility.id && this.isEditting === false) {
        this.form.disable();
      }
    }
  }

  loadMonLogTypes(): void {
    this._monLogApi
      .loadAvailableLogTypes()
      .pipe(take(1))
      .subscribe((data) => {
        const clone = { ...data };
        const types = clone.data.getMonLogTypes.items;
        this.monLogTypes = types.filter((t) => t.enabled === true);
      });
  }

  loadContactLists() {
    this._contactListApi.findContactListsForDropdown().subscribe(
      (contactLists: ConfigItem[]) => {
        if (this.canRemoveContactListFromFacility) {
          this.contactListsForDropdowns = [{ name: 'None', id: null }];
        }

        this.contactListsForDropdowns.push(
          ...contactLists.sort((a, b) => sortByProperty(a, b, 'name'))
        );
      },
      (error) => {
        console.log(error);
        this._logAndMessage.translateToErrorMessage({
          headerKey: 'COMMON.MESSAGES.HEADERS.RETRIEVE_ERROR',
          bodyKey: 'COMMON.MESSAGES.ERROR.RETRIEVE_ERROR_MESSAGE',
        });
      }
    );
  }

  findContactList() {
    this.contactList = null;
    this.managementContactList = null;
    if (!this.facility?.id) {
      // No facility id available so don't look it up
      return;
    }

    // get Contact List for the facility - this checks facility directly, then area, then region & returns the first one it finds
    this._contactListApi
      .getContactListForFacility(this.facility.id)
      .pipe(take(1))
      .subscribe((list: ContactList) => {
        if (list?.id === undefined) {
          // no contact list for this facility
          return;
        }
        this.contactList = list;

        this._contactListApi
          .getContactListSchedules(this.contactList.id)
          .pipe()
          .subscribe(({ data }) => {
            this.events = [];
            data.getContactListSchedules.forEach((s) => {
              let timeConvertedSchedule =
                this._contactListBuilder.buildContactListSchedule(s);
              this.events.push({
                start: timeConvertedSchedule.startTime,
                end: timeConvertedSchedule.endTime,
                ...s,
              });
            });
          });
        this._cdRef.detectChanges();
        this._cdRef.markForCheck();
      });

    this._contactListApi
      .getManagementContactListForFacility(this.facility.id)
      .pipe(take(1))
      .subscribe((list: ContactList) => {
        if (list?.id === undefined) {
          // no contact list for this facility
          return;
        }
        this.managementContactList = list;
      });
  }

  saveFacility(fromAction = false) {
    if (
      !this.canCreate &&
      !this.canEdit &&
      !this.canAddContactListToFacility &&
      !this.canRemoveContactListFromFacility
    ) {
      return;
    }
    this.saving = true;
    if (!this.form.invalid || fromAction === true) {
      const fac = Object.assign({}, this.form.getRawValue());
      const facility = Object.assign(
        {},
        {
          address: fac.address,
          facilityName: fac.facilityName,
          facilityUid: fac.facilityUid,
          facilityType: fac.facilityType,
          aor: fac.aor,
          aorName: fac.aorName,
          company: fac.company,
          companyName: fac.companyName,
          businessUnit: fac.businessUnit,
          notes: fac.notes,
          gps: fac.gps,
          phone: fac.phone,
          altPhone: fac.altPhone,
          scadaId: fac.scadaId,
          id: fac.id,
          ContactListId: fac.ContactListId,
          managementContactListId: fac.managementContactListId,
          version: fac.version,
          deletedAt: null,
          ownerId: fac.owner?.value,
          areaId: fac.area?.value,
          flocNumber: fac?.flocNumber,
          timezone: fac?.timezone,
        }
      );

      if (!fac.id) {
        this.createFacility(facility);
      } else {
        this.updateFacility();
      }
    } else {
      this.saving = false;
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'LOCATION.MESSAGES.HEADERS.SAVE_FACILITY',
        bodyKey: 'LOCATION.MESSAGES.ERROR.SAVING_FACILITY_ERROR',
      });
    }
  }

  createFacility(facility) {
    if (!this.canCreate) {
      return;
    }
    facility.id = uuid();
    facility.updater = null;
    facility.updatedAt = null;
    facility.createdAt = null;
    let bu = this.availableBusinessUnits.find(
      (abu) => abu.value === facility.businessUnit
    );

    const facilityInput = Object.assign(
      {},
      {
        id: facility.id,
        ContactListId: facility.ContactListId,
        managementContactListId: facility.managementContactListId,
        address: facility.address ? facility.address : null,
        facilityName: facility.facilityName,
        facilityUid: facility.facilityUid,
        facilityType: facility.facilityType,
        aor: facility.aor,
        company: facility.company,
        gps: facility.gps ? facility.gps : null,
        scadaId: facility.scadaId ? facility.scadaId : null,
        phone: facility.phone ? facility.phone : null,
        altPhone: facility.altPhone ? facility.altPhone : null,
        notes: facility.notes,
        businessUnit: {
          id: bu.value,
          name: bu.label,
        },
        ownerId: facility.ownerId,
        areaId: facility.areaId,
        flocNumber: facility.flocNumber,
        timezone: facility.timezone.value,
      }
    );

    if (this.unPersistedNewFacilityLocations) {
      this.unPersistedNewFacilityLocations.forEach((i) => {
        i.update.FacilityId = facilityInput.id;
        i.value.FacilityId = facilityInput.id;
      });
      facilityInput['Locations'] = this.unPersistedNewFacilityLocations.map(
        (i) => i.update
      );
    }

    let facName = null;
    if (facilityInput['facilityType']) {
      const array = this.types.filter(
        (t) => t.value === facilityInput['facilityType']
      );
      if (array && array.length > 0) {
        facName = array[0].label;
      }
    }

    this._facilityApi
      .createFacility(facilityInput)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
            headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
          });
          const clone = Object.assign({}, data);
          this.facility = Object.assign(
            {},
            this._facilityBuilder.buildFacility(
              Object.assign({}, clone.createFacility),
              []
            )
          );
          this._facilityTableService.setBuiltGqlForFacility(null);
          this.saving = false;
          this.findContactList();
          this.updateForm();
          this.form.markAsPristine();
          this._cdRef.markForCheck();
        },
        (error) => {
          this.saving = false;
          this.setEditting(false);
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'LOCATION.MESSAGES.HEADERS.SAVE_FACILITY',
            bodyKey: 'LOCATION.MESSAGES.ERROR.SAVING_FACILITY_ERROR',
          });
        },
        () => {
          if (!this._deviceService.isMobile()) {
            const oldTab = TabService.getInstance().getActiveTab();
            const tab = TabService.getInstance().buildNewTab(
              FACILITY_DETAIL_CONTAINER,
              true,
              null,
              this.facility.id
            );
            TabService.getInstance().replaceTab(oldTab, tab);
            this.changesEvent.emit({
              isDirty: false,
              index: this.index(),
              id: this.id,
            });
          }
        }
      );
  }

  updateFacility() {
    if (
      !this.canEdit &&
      !this.canAddContactListToFacility &&
      !this.canRemoveContactListFromFacility
    ) {
      return;
    }
    // need to get dirty values from main form.
    const facilityInput = getDirtyValues(this.form);
    facilityInput['locations'] = this.unPersistedExistingFacilityLocations.map(
      (loc) => loc.update
    );

    if (this.form.controls['aor'].touched) {
      facilityInput['aor'] = this.form.controls['aor'].value;
    }

    if (this.form.controls['company'].touched) {
      facilityInput['company'] = this.form.controls['company'].value;
    }
    let facName = null;
    if (facilityInput['facilityType']) {
      const array = this.types.filter(
        (t) => t.value === facilityInput['facilityType']
      );
      if (array && array.length > 0) {
        facName = array[0].label;
      }
    }

    // If the contactlistid is pristine, that means that we haven't
    // removed it or changed it, and so it won't come in from getDirtyValues
    // but our lambda will remove the list if we don't send it back
    // so we need to re-send it every time we make other changes
    // to the list
    if (this.form.controls['ContactListId'].pristine) {
      facilityInput['ContactListId'] =
        this.form.controls['ContactListId'].value;
    }

    if (this.form.controls['managementContactListId'].pristine) {
      facilityInput['managementContactListId'] =
        this.form.controls['managementContactListId'].value;
    }

    if (this.form.controls['businessUnit'].pristine) {
      facilityInput['businessUnit'] = this.form.controls['businessUnit'].value
        ? this.form.controls['businessUnit'].value
        : [];
    }

    if (this.form.controls['owner'].pristine) {
      facilityInput['owner'] = this.form.controls['owner'].value;
    }

    if (this.form.controls['area'].pristine) {
      facilityInput['area'] = this.form.controls['area'].value
        ? this.form.controls['area'].value
        : null;
    }

    if (this.form.controls['flocNumber'].pristine) {
      facilityInput['flocNumber'] = this.form.controls['flocNumber'].value
        ? this.form.controls['flocNumber'].value
        : null;
    }

    if (this.form.controls['timezone'].pristine) {
      facilityInput['timezone'] = this.form.controls['timezone'].value.value
        ? this.form.controls['timezone'].value
        : null;
    }

    if (!isNullOrUndefined(this.facility)) {
      const update = this._facilityBuilder.buildUpdateStatement(
        this.facility,
        facilityInput
      );
      const locations = this.unPersistedExistingFacilityLocations;

      this._facilityApi
        .updateFacility(
          this.facility.id,
          this.facility,
          update,
          locations,
          facName
        )
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
              headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
            });

            const clone = Object.assign({}, data);
            this.facility = Object.assign(
              {},
              this._facilityBuilder.buildFacility(clone.updateFacility, [])
            );
            this._facilityTableService.setBuiltGqlForFacility(null);
            this.findContactList();
            this._cdRef.markForCheck();
            this.updateForm();
            this.form.markAsPristine();
            this.saving = false;
            this.setEditting(false);
          },
          (error) => {
            console.error(error);
            this.saving = false;
            this.setEditting(false);
            this._logAndMessage.translateToErrorMessage({
              headerKey: 'LOCATION.MESSAGES.HEADERS.SAVE_FACILITY',
              bodyKey: 'LOCATION.MESSAGES.ERROR.SAVING_FACILITY_ERROR',
            });
          },
          () => {
            this.changesEvent.emit({
              isDirty: false,
              index: this.index(),
              id: this.id,
            });
          }
        );
    }
  }

  createLog(category) {
    const monLog = this._monLogBuilder.buildMonLogFromFacility(
      this.facility,
      this.contactList,
      category
    );
    let typeArray = [];
    if (category === 'O') {
      typeArray = [
        ...this.monLogTypes.filter(
          (t) => t.name.toLowerCase() === 'emergency locate'
        ),
      ];
    } else if (category === M_AND_R_LOG_CATEGORY) {
      typeArray = [
        ...this.monLogTypes
          .filter(
            (t) => t.name.toLowerCase() === M_AND_R_MAINTENANCE.toLowerCase()
          )
          .map((t) => ({ label: t.name, value: t.id })),
      ];
    } else if (category === A_AND_E_LOG_CATEGORY) {
      typeArray = [
        ...this.monLogTypes
          .filter(
            (t) => t.name.toLowerCase() === M_AND_R_MAINTENANCE.toLowerCase()
          )
          .map((t) => ({ label: t.name, value: t.id })),
      ];
    }

    monLog.MonLogTypes = typeArray;
    this._monLogTableService.setSelected(monLog);
    const tab = TabService.getInstance().buildNewTab(
      MONLOG_DETAILS_CONTAINER,
      true
    );
    TabService.getInstance().openTab(tab, true);
  }

  /**
   * Need to manually remove the ___-___-____ mask from the input mask so
   * the validation works.
   * @param field
   */
  fixMask(field) {
    const value: string = this.form.controls[field].value;
    if (value && value.indexOf('_') > -1) {
      this.form.controls[field].setValue('');
      this.form.updateValueAndValidity();
    }
  }

  setEditting(editing: boolean) {
    if (this.canEdit || this.canCreate) {
      this.isEditting = editing;

      if (editing) {
        this.form.enable();
        this.form.get('updater').disable();
      } else {
        this.form.disable();
      }
    } else if (
      this.canAddContactListToFacility ||
      this.canRemoveContactListFromFacility
    ) {
      this.isEditting = editing;
    } else {
      this.isEditting = false;
      this.form.disable();
      this._logAndMessage.translateToWarnMessage({
        bodyKey: 'COMMON.MESSAGES.WARN.ADMIN_RIGHTS',
        headerKey: 'COMMON.MESSAGES.HEADERS.AUTHORIZATION_MESSAGE',
      });
    }
  }

  tabChanged($event): void {
    if (!isNullOrUndefined($event)) {
      this.tabIndex = $event.index;
    }
  }

  contactSearch($event: AutoCompleteCompleteEvent) {
    if ($event.query) {
      this.filteredContacts = this.contacts
        .filter((c) =>
          (c.firstName + ' ' + c.lastName)
            .toLowerCase()
            .startsWith($event.query.toLowerCase())
        )
        .filter((c: Contact) => c.inactiveAdAccount === false)
        .map((c) => ({ label: c.firstName + ' ' + c.lastName, value: c.id }));
    } else {
      this.filteredContacts = this.contacts.map((c) => ({
        label: c.firstName + ' ' + c.lastName,
        value: c.id,
      }));
    }
  }

  regionSearch($event: AutoCompleteCompleteEvent) {
    if ($event.query) {
      this.filteredRegions = this.regions
        .filter((r) =>
          r.name.toLowerCase().startsWith($event.query.toLowerCase())
        )
        .map((r) => ({ label: r.name, value: r.id }));
    } else {
      this.filteredRegions = this.regions.map((r) => ({
        label: r.name,
        value: r.id,
      }));
    }
  }

  selectRegion($event: AutoCompleteSelectEvent) {
    // refilter areas
    this.filteredAreas = this.getFilteredAreas();

    // if the area is already set,
    // ONLY reset it if the area is not in the new filteredAreas
    if (!isNullOrUndefined(this.form.controls['area'].value)) {
      if (
        !this.filteredAreas.find(
          (a) => a.value === this.form.controls['area'].value
        )
      ) {
        this.form.patchValue({
          area: null,
        });
      }
    }
  }

  getFilteredAreas() {
    if (this.form.controls['region'].value) {
      return this.areas
        .filter((a) => a.regionId === this.form.controls['region'].value.value)
        .map((a) => ({ label: a.name, value: a.id }));
    }

    return this.areas.map((a) => ({ label: a.name, value: a.id }));
  }

  areaSearch($event: AutoCompleteCompleteEvent) {
    this.filteredAreas = this.getFilteredAreas();
    if ($event.query) {
      this.filteredAreas = this.filteredAreas
        .filter((a) =>
          a.label.toLowerCase().startsWith($event.query.toLowerCase())
        )
        .map((a) => ({ label: a.label, value: a.value }));
    }
  }

  areaSelected($event: AutoCompleteSelectEvent) {
    const interior = $event.value;
    if (
      !isNullOrUndefined(interior) &&
      isNullOrUndefined(this.form.controls['region'].value)
    ) {
      const regionId = this.areas.find((a) => a.id == interior.value).regionId;
      const setRegion = this.regions
        .filter((r) => r.id === regionId)
        .map((r) => ({ label: r.name, value: r.id }))
        .pop();
      this.form.patchValue({
        region: setRegion,
      });
    }
  }

  findOwner() {
    if (this.facility.ownerId && this.contacts) {
      return this.contacts
        .filter((c) => c.id == this.facility.ownerId)
        .map((c) => ({ label: c.firstName + ' ' + c.lastName, value: c.id }))
        .shift();
    }
    return null;
  }

  findArea() {
    if (this.facility.areaId && this.areas) {
      return this.areas
        .filter((a) => a.id == this.facility.areaId)
        .map((r) => ({ label: r.name, value: r.id }))
        .shift();
    }
    return null;
  }

  findRegion() {
    if (this.facility.areaId && this.regions && this.areas) {
      return this.regions
        .filter(
          (r) =>
            r.id ==
            this.areas.find((a) => a.id == this.facility.areaId).regionId
        )
        .map((r) => ({ label: r.name, value: r.id }))
        .shift();
    }
    return null;
  }

  loadTimeZones() {
    this.timeZones = [...timezones];

    this.timeZones = [{ label: 'Choose', value: null }, ...this.timeZones];
  }

  onTimeZoneChange($event, customForm: AbstractControl = this.form) {
    const fg = customForm as UntypedFormGroup;
    this.timezone = $event.value.value;
    fg.patchValue({
      timeZone: this.timezone,
    });
  }

  syncSapOutagesForFacility(): void {
    if (!this.facility.flocNumber) {
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'COMMON.MESSAGES.HEADERS.NO_FLOC',
        bodyKey: 'COMMON.MESSAGES.ERROR.NO_FLOC_SYNC',
      });
      return;
    }

    this.loading = true;
    this._sapApi.syncSapOutagesForFacility(this.facility.flocNumber).subscribe(
      (result: boolean) => {
        // As long as we didn't get an exception from the backend, we consider this successful,
        // any errors while creating DB records will be captured in emails sent to the application
        // support group
        this._logAndMessage.translateToSuccessMessage({
          headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          bodyKey: 'COMMON.MESSAGES.SUCCESS.SUCCESSFUL_SYNC',
        });
        this.loading = false;
      },
      (error) => {
        console.error(error);
        this._logAndMessage.translateToErrorMessage({
          headerKey: 'COMMON.MESSAGES.HEADERS.FAILED_SYNC',
          bodyKey: 'COMMON.MESSAGES.ERROR.FAILED_SYNC',
        });
        this.loading = false;
      }
    );
  }

  onNotepadChange() {
    this.initialize();
  }

  onLocationsDialogHidden() {
    this.displayLocationsDialog = false;
  }

  onLocationsDialogUpdated($event: any[]) {
    this.displayLocationsDialog = false;

    if (this.locationDialogType === 'State') {
      this.facilityStates = $event.map((state) => state.label);

      this.facilityCounties = this.facilityCounties.filter((sc) =>
        this.facilityStates.some((state) => state === sc.state)
      );
      this.facilityTownships = this.facilityTownships.filter((st) =>
        this.facilityCounties.some((sc) => sc.county === st.county)
      );
      this.updateLocationInformation();
    } else if (this.locationDialogType === 'County') {
      this.facilityCounties = $event;

      this.facilityTownships = this.facilityTownships.filter((st) =>
        this.facilityCounties.some((sc) => sc.county === st.county)
      );
      this.updateLocationInformation();
    } else {
      this.facilityTownships = $event;
      this.updateLocationInformation();
    }
  }

  updateLocationInformation() {
    if (
      !isNullOrUndefined(this.facility) &&
      !isNullOrUndefined(this.facility.id)
    ) {
      // Editing Existing Facility
      this.unPersistedExistingFacilityLocations = [];

      this.facilityTownships.forEach((location) => {
        // Added new location
        if (!this.facility.locations.some((loc) => loc.id === location.id)) {
          const locInput = {
            update: {
              mappedId: '',
              LocationId: location.id,
              FacilityId: this.facility.id,
              dirtyStatus: DirtyStatus.NEW,
            },
            value: location,
          };

          this.unPersistedExistingFacilityLocations.push(locInput);
        }
      });

      this.facility.locations.forEach((location) => {
        // Removed existing location
        if (!this.facilityTownships.some((loc) => loc.id === location.id)) {
          const locInput = {
            update: {
              mappedId: '',
              LocationId: location.id,
              FacilityId: this.facility.id,
              dirtyStatus: DirtyStatus.DELETED,
            },
            value: location,
          };

          this.unPersistedExistingFacilityLocations.push(locInput);
        }
      });
    } // Adding New Facility
    else {
      this.unPersistedNewFacilityLocations = [];

      this.facilityTownships.forEach((location) => {
        const fg = this.addLocationToFacForm as UntypedFormGroup;
        fg.patchValue({
          locationId: location.id,
          state: location.state,
          county: { county: location.county, state: location.state },
          township: location,
        });

        fg.controls['county'].markAsDirty();
        fg.controls['state'].markAsDirty();
        fg.controls['township'].markAsDirty();

        const raw = this.addLocationToFacForm.getRawValue();
        const newLocationFac = this._facilityBuilder.buildNewFacilityLocation(
          this.facility,
          raw
        );

        this.unPersistedNewFacilityLocations.push(newLocationFac);
        this.facility.locations = [
          ...this.facility.locations,
          newLocationFac.value,
        ];

        this.addLocationToFacForm.reset();
      });
    }
  }

  toggleLocationsDialog(type: string) {
    this.locationDialogType = type;
    this.displayLocationsDialog = true;
    this.setEditting(true);
  }

  getStateListForDisplay() {
    if (this.facilityStates?.length > 0) {
      return this.facilityStates.join(', ');
    } else {
      return 'Add State';
    }
  }

  getCountyListForDisplay() {
    if (this.facilityCounties?.length > 0) {
      return this.facilityCounties.map((loc) => loc.county).join(', ');
    } else {
      return 'Add County';
    }
  }

  getTownshipListForDisplay() {
    if (this.facilityTownships?.length > 0) {
      return this.facilityTownships.map((loc) => loc.township).join(', ');
    } else {
      return 'Add Township';
    }
  }
}
