import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, viewChild, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FACILITY_DETAIL_CONTAINER } from 'src/app/constants/common.constants';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { Facility } from 'src/app/model/locations/facility';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { FACILITY_TABLE_DEFINITION } from '../../definitions/facility-table-definition';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-facility-container',
    templateUrl: './facility-container.component.html',
    styleUrls: ['./facility-container.component.scss'],
    standalone: false
})
export class FacilityContainerComponent
  extends SearchContainerComponent<Facility>
  implements OnInit, OnDestroy, AfterViewInit
{
  private _translateService = inject(TranslateService);
  private _breadCrumbBuilderService = inject(BreadCrumbBuilderService);
  protected _deviceService: DeviceService;
  private _locationApi = inject(LocationApiService);
  private _facilityApi = inject(FacilityApiService);
  private _facilityBuilder = inject(FacilityBuilderService);
  private _authApi = inject(AuthApiService);
  protected _facilityTableService = inject(FacilityTableService);
  protected _cdRef: ChangeDetectorRef;
  protected _auth: AuthService;
  protected _loader: LoadingService;

  readonly businessUnitFilter = viewChild<BusinessUnitFilterComponent>('buFilter');
  allFacs: any[];
  loading: boolean;
  displayDialog = false;
  states: any[];
  filteredStates: any[];
  counties: any[];
  filteredCounties: any[];
  townships: any[];
  filteredTownships: any[];
  timerSub: Subscription;
  facilitiesLoading: boolean;
  facilitiesLoadingEvent = new EventEmitter<any>();
  allFacilities: Facility[];
  canCreate = false;
  canEdit = false;
  sub: Subscription;
  mobileFilterCollapsed = true;
  refreshSubscription: Subscription;

  constructor() {
    const _deviceService = inject(DeviceService);
    const _cdRef = inject(ChangeDetectorRef);
    const _auth = inject(AuthService);
    const _loader = inject(LoadingService);

    super(_deviceService, _loader, _auth, _cdRef);
    this._deviceService = _deviceService;
    this._cdRef = _cdRef;
    this._auth = _auth;
    this._loader = _loader;

  }

  ngOnInit() {
    this.setTableService(this._facilityTableService);
    this._translateService
      .get('LOCATION.SCREEN.FACILITY')
      .subscribe((label) => {
        if (this._deviceService.isMobile()) {
          this._breadCrumbBuilderService.resetAndAddBreadCrumb({
            label: label,
            routerLink: null,
          });
        } else {
          TabService.getInstance().updateActiveTabLabel(label);
        }
        this.screenName = label;
      });
    this.columns = FACILITY_TABLE_DEFINITION;

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }

    const refreshInterval = interval(60000);
    this.refreshSubscription = refreshInterval.subscribe(() => {
      this.refresh(false);
    });
  }

  initialize() {
    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.LOCATIONS.FACILITIES.CREATE_FACILITIES,
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.LOCATIONS.FACILITIES.EDIT_FACILITIES,
    ]);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.refreshSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.applyPreviousFilters();
    this.applyPreviousData();
  }

  /**
   * Method called when the user hits the clear button.
   */
  clearScreen() {
    this.lazyLoad({});
    this.grid().resetTable();
    this._facilityTableService.clearResults();
    this.elements = [];
    this.allFacilities = [];
  }

  newFacility() {
    const tab = TabService.getInstance().buildNewTab(
      FACILITY_DETAIL_CONTAINER,
      true
    );
    TabService.getInstance().openTab(tab);
  }

  changeViewEvent($event) {
    const tab = TabService.getInstance().buildNewTab(
      FACILITY_DETAIL_CONTAINER,
      true,
      null,
      $event.data.id
    );
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(tab);
    } else {
      TabService.getInstance().openTab(tab);
    }
  }

  queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      sort[$event.lazy.sortField] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._facilityTableService.setLastSortField($event.lazy.sortField);
      this._facilityTableService.setLastSortDirection($event.lazy.sortOrder);
    } else {
      sort['facilityName'] = 'ASC';
      this._facilityTableService.setLastSortField('facilityName');
      this._facilityTableService.setLastSortDirection(-1);
    }

    this._facilityApi
      .queryForFacilities(req.pageSize, req.page, query, sort)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          if (data) {
            const clone = Object.assign({}, data);
            this.elements = clone.queryForFacilities.items.map((f) =>
              this._facilityBuilder.buildFacilitySearch(f)
            );
            this.totalRecords = clone.queryForFacilities.totalRecords;
            this._facilityTableService.setLastTotalRecords(this.totalRecords);
            this.loading = false;
            this._cdRef.markForCheck();
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  handleLazyLoad(req, $event, filters) {
    let selectedBusinessUnits = [];
    const businessUnitFilter = this.businessUnitFilter();
    if (businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    filters['businessUnits'] = selectedBusinessUnits;

    const query = this._facilityBuilder.buildFacilityQuery(filters);
    this.queryNetwork(req, $event, query);
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad({ lazy: this._facilityTableService.getLastLazyLoad() });
  }

  mobileFilter($event) {
    const newLazy: LazyLoadEvent = {
      filters: {
        ...this.grid().table().filters,
        facilityName: $event.facilityName
          ? {
              value: $event.facilityName,
              matchMode: 'contains',
            }
          : null,
        state: $event.state
          ? {
              value: $event.state,
              matchMode: 'contains',
            }
          : null,
        county: $event.county
          ? {
              value: $event.county,
              matchMode: 'contains',
            }
          : null,
        township: $event.township
          ? {
              value: $event.township,
              matchMode: 'contains',
            }
          : null,
      },
    };
    this.mobileFilterCollapsed = true;
    this._facilityTableService.setLastLazyLoad(newLazy);
    this.lazyLoad({ lazy: this._facilityTableService.getLastLazyLoad() });
  }

  refresh(setLoading = true): void {
    this.lazyLoad(
      { lazy: this._facilityTableService.getLastLazyLoad() },
      setLoading
    );
  }
}
