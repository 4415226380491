import { Injectable, inject } from '@angular/core';
import { WatchQueryOptions } from 'apollo-client';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TC_CLAIMS_HEADER } from '../constants/auth-constants';
import { createRegion } from '../graphql/facilities/create-region';
import { deleteRegion } from '../graphql/facilities/delete-region';
import { getAvailableRegions } from '../graphql/facilities/get-available-regions';
import { getRegion } from '../graphql/facilities/get-region';
import { queryForRegions } from '../graphql/facilities/query-for-regions';
import { updateRegion } from '../graphql/facilities/update-region';
import { AppsyncService } from './appsync.service';
import { AuthApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class RegionApiService {
  private _api = inject(AppsyncService);
  private _authApi = inject(AuthApiService);


  getAvailableRegions(): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getAvailableRegions,
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'cache-first',
        };
        return client.query(options);
      })
    );
  }

  queryForRegions(
    limit: number,
    page: number,
    query: any,
    sort: any
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: queryForRegions,
          fetchPolicy: 'no-cache',
          variables: {
            limit,
            page,
            query,
            sort,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  getRegion(id): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getRegion,
          variables: {
            id,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
        };
        return client.query(options);
      })
    );
  }

  deleteRegion(id): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: deleteRegion,
          variables: { id: id },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  updateRegion(id, regionInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: updateRegion,
          variables: { id: id, input: regionInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  createRegion(regionInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: createRegion,
          variables: { input: regionInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }
}
