import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, input, output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { take } from 'rxjs/operators';
import { CLAIMS } from 'src/app/constants/auth-constants';
import { CoreModule } from 'src/app/core/core.module';
import { FacilityNotepadEntry } from 'src/app/model/locations/facility-notepad-entry';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';

@Component({
  selector: 'app-facility-notepad-entry',
  imports: [ButtonModule, CoreModule, CommonModule],
  templateUrl: './facility-notepad-entry.component.html',
  styleUrl: './facility-notepad-entry.component.scss',
})
export class FacilityNotepadEntryComponent implements OnInit {
  private facilityApi = inject(FacilityApiService);
  private confirmationService = inject(ConfirmationService);
  private authApi = inject(AuthApiService);
  private _auth = inject(AuthService);
  private contactApi = inject(ContactApiService);

  readonly entry = input<FacilityNotepadEntry>(undefined);

  readonly editEntryEvent = output<FacilityNotepadEntry>();
  readonly entryDeletedEvent = output<FacilityNotepadEntry>();

  entryWorking: boolean = false;
  userReportsNames: string[] = [];

  ngOnInit(): void {
    this.loadUserReports();
  }

  editEntry(entry: FacilityNotepadEntry): void {
    this.editEntryEvent.emit(entry);
  }

  deleteEntry(entry: FacilityNotepadEntry): void {
    this.confirmationService.confirm({
      header: 'Delete Entry?',
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: 'OK',
      rejectLabel: 'Cancel',
      message: "Are you sure you'd like to delete this facility notepad entry?",
      accept: () => {
        this.entryWorking = true;
        this.facilityApi.deleteFacilityNotepadEntry(entry.id).subscribe(
          (result) => {
            this.entryWorking = false;
            this.entryDeletedEvent.emit(this.entry());
          },
          (error) => {
            console.error(error);
            this.entryWorking = false;
          }
        );
      },
      reject: () => {},
    });
  }

  canEditThisEntry(): boolean {
    if (
      this.authApi.doesUserHaveAtLeastOneClaimFromList([
        CLAIMS.LOCATIONS.FACILITIES.NOTEPAD_ENTRIES.EDIT_ALL_FACILITY_NOTEPAD_ENTRIES,
        CLAIMS.LOCATIONS.FACILITIES.NOTEPAD_ENTRIES.ARCHIVE_ALL_FACILITY_NOTEPAD_ENTRIES,
      ])
    ) {
      return true;
    }

    if (
      this.authApi.doesUserHaveAtLeastOneClaimFromList([
        CLAIMS.LOCATIONS.FACILITIES.NOTEPAD_ENTRIES.EDIT_OWN_FACILITY_NOTEPAD_ENTRIES,
        CLAIMS.LOCATIONS.FACILITIES.NOTEPAD_ENTRIES.ARCHIVE_OWN_FACILITY_NOTEPAD_ENTRIES,
      ]) &&
      this.isOwnEntry()
    ) {
      return true;
    }

    if (
      this.authApi.doesUserHaveAtLeastOneClaimFromList([
        CLAIMS.LOCATIONS.FACILITIES.NOTEPAD_ENTRIES.EDIT_DIRECT_REPORTS_FACILITY_NOTEPAD_ENTRIES,
        CLAIMS.LOCATIONS.FACILITIES.NOTEPAD_ENTRIES.ARCHIVE_DIRECT_REPORTS_FACILITY_NOTEPAD_ENTRIES,
      ])
    ) {
      return this.userReportsNames.some((name) =>
        this.entry().createdBy.toLowerCase().startsWith(name)
      );
    }
  }

  canDeleteThisEntry(): boolean {
    if (
      this.authApi.doesUserHaveAllClaimsFromList([
        CLAIMS.LOCATIONS.FACILITIES.NOTEPAD_ENTRIES.DELETE_ALL_FACILITY_NOTEPAD_ENTRIES,
      ])
    ) {
      return true;
    }

    if (
      this.authApi.doesUserHaveAllClaimsFromList([
        CLAIMS.LOCATIONS.FACILITIES.NOTEPAD_ENTRIES.DELETE_OWN_FACILITY_NOTEPAD_ENTRIES,
      ]) &&
      this.isOwnEntry()
    ) {
      return true;
    }

    if (
      this.authApi.doesUserHaveAllClaimsFromList([
        CLAIMS.LOCATIONS.FACILITIES.NOTEPAD_ENTRIES.DELETE_DIRECT_REPORTS_FACILITY_NOTEPAD_ENTRIES,
      ])
    ) {
      return this.userReportsNames.some((name) =>
        this.entry().createdBy.toLowerCase().startsWith(name)
      );
    }
  }

  isOwnEntry(): boolean {
    const entry = this.entry();
    return (
      entry.createdBy === this._auth.getUserName() ||
      this._auth.getEmail().startsWith(entry.createdBy)
    );
  }

  loadUserReports(): void {
    this.contactApi
      .queryForWhoReportsToMe(this._auth.getEmail())
      .pipe(take(1))
      .subscribe(({ data }) => {
        let usernames = data.queryWhoReportsToMe.map((item) =>
          `${item.firstName} ${item.lastName}`.toLowerCase()
        );
        this.userReportsNames = usernames;
      });
  }
}
