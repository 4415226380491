import { Component, OnInit, output } from '@angular/core';

@Component({
    selector: 'app-region-mobile-filter',
    templateUrl: './region-mobile-filter.component.html',
    styleUrls: ['./region-mobile-filter.component.scss'],
    standalone: false
})
export class RegionMobileFilterComponent implements OnInit {

  readonly mobileFilterEvent = output<any>();

  name: string;
  owner: string;
  contactList: string;
  areas: string;

  constructor() { }

  ngOnInit() {
  }

  mobileFilter() {
    this.mobileFilterEvent.emit({name: this.name, owner: this.owner, contactList: this.contactList, areas: this.areas });
  }

  clear() {
    this.name = null;
    this.owner = null;
    this.contactList = null;
    this.areas = null;
    this.mobileFilter();
  }

}
