import { Component, Input, inject } from '@angular/core';
import {
  CONTACT_DETAIL_CONTAINER,
  CONTACT_LIST_DETAIL_CONTAINER,
} from 'src/app/constants/common.constants';
import { ContactsModule } from 'src/app/contacts/contacts.module';
import { TabService } from 'src/app/core/services/tab.service';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { ContactTableService } from 'src/app/services/contact-table.service';

@Component({
    selector: 'app-facility-contact-list-embed',
    imports: [ContactsModule],
    templateUrl: './facility-contact-list-embed.component.html',
    styleUrl: './facility-contact-list-embed.component.scss'
})
export class FacilityContactListEmbedComponent {
  private _contactTable = inject(ContactTableService);

  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() contactList: ContactList;
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() managementContactList: ContactList;

  openContactList(contactList: ContactList) {
    const tab = TabService.getInstance().buildNewTab(
      CONTACT_LIST_DETAIL_CONTAINER,
      true,
      null,
      contactList.id
    );
    TabService.getInstance().openTab(tab);
  }

  openProfile(id: string) {
    // clear out the selected to make sure a previously viewed contact isn't used.
    this._contactTable.setSelected(undefined);
    this._contactTable.setScreenEmbedded(false);
    const tab = TabService.getInstance().buildNewTab(
      CONTACT_DETAIL_CONTAINER,
      true,
      null,
      id
    );
    TabService.getInstance().openTab(tab);
  }
}
