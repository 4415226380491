import { Component, OnInit, output } from '@angular/core';

@Component({
    selector: 'app-facility-mobile-filter',
    templateUrl: './facility-mobile-filter.component.html',
    styleUrls: ['./facility-mobile-filter.component.scss'],
    standalone: false
})
export class FacilityMobileFilterComponent implements OnInit {

  readonly mobileFilterEvent = output<any>();

  facilityName: string;
  state: string;
  county: string;
  township: string;

  constructor() { }

  ngOnInit() {
  }

  mobileFilter() {
    this.mobileFilterEvent.emit({facilityName: this.facilityName, state: this.state, county: this.county, township: this.township});
  }

  clear() {
    this.facilityName = null;
    this.state = null;
    this.county = null;
    this.township = null;
    this.mobileFilter();
  }

}
