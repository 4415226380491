<div id="area-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  @if (ifDeviceMatches([DeviceSize.XL, DeviceSize.LG, DeviceSize.MD])) {
    <div>
      @if (elements) {
        <div class="ui-g ui-fluid" id="area-action-buttons">
          <div class="buttons">
            @if (canCreate && !isMobileDevice()) {
              <div>
                <button
                  pButton
                  class="width-eighty"
                  label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
                  icon="fa fa-fw fa-plus-circle"
                  (click)="newArea()"
                ></button>
              </div>
            }
            <button
              pButton
              class="refresh-button width-twenty"
              icon="fa fa-fw fa-refresh"
              (click)="refresh()"
            ></button>
            @if (!isMobileDevice()) {
              <div>
                <button
                  pButton
                  label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
                  icon="fa fa-fw fa-eraser"
                  (click)="clearScreen()"
                ></button>
              </div>
            }
            <app-progress-spinner [enabled]="loading"></app-progress-spinner>
          </div>
        </div>
      }
      <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
      <div [style.display]="!elements ? 'none' : ''">
        <app-general-grid
          [values]="elements"
          [columns]="columns"
          [loading]="loading"
          [deviceSize]="getDeviceSize()"
          [rows]="rows"
          [totalRecords]="totalRecords"
          [filterable]="true"
          [screenName]="screenName"
          [lazy]="true"
          (lazyLoad)="lazyLoad($event)"
          [includeExportCSV]="false"
          (changeViewEvent)="changeViewEvent($event)"
          scrollHeight="calc(100vh - 256px)"
          [usePaginator]="true"
          [rowsPerPageOptions]="[50, 100, 200]"
          #grid
          >
        </app-general-grid>
      </div>
    </div>
  }

  @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
    <p-panel
      [showHeader]="true"
      header="Filters"
      [toggleable]="true"
      [(collapsed)]="mobileFilterCollapsed"
      >
      <div class="ui-g ui-fluid">
        <div class="ui-g-12">
          <app-area-mobile-filter
            (mobileFilterEvent)="mobileFilter($event)"
          ></app-area-mobile-filter>
        </div>
      </div>
    </p-panel>
  }
  @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
    <div>
      @if (elements) {
        <div class="ui-g ui-fluid" id="area-action-buttons">
          <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
          <div [style.display]="!elements ? 'none' : ''">
            <app-general-grid
              [values]="elements"
              [columns]="columns"
              [loading]="loading"
              [deviceSize]="getDeviceSize()"
              [rows]="rows"
              [totalRecords]="totalRecords"
              [filterable]="true"
              [screenName]="screenName"
              [lazy]="true"
              (lazyLoad)="lazyLoad($event)"
              [includeExportCSV]="false"
              (changeViewEvent)="changeViewEvent($event)"
              scrollHeight="calc(100vh - 175px)"
              [usePaginator]="true"
              [rowsPerPageOptions]="[50, 100, 200]"
              #grid
              >
            </app-general-grid>
          </div>
        </div>
      }
    </div>
  }
</div>
