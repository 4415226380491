import { Component, OnInit, output } from '@angular/core';

@Component({
    selector: 'app-location-mobile-filter',
    templateUrl: './location-mobile-filter.component.html',
    styleUrls: ['./location-mobile-filter.component.scss'],
    standalone: false
})
export class LocationMobileFilterComponent implements OnInit {

  readonly mobileFilterEvent = output<any>();

  state: string;
  county: string;
  township: string;

  constructor() { }

  ngOnInit() {
  }

  mobileFilter() {
    this.mobileFilterEvent.emit({state: this.state, county: this.county, township: this.township});
  }

  clear() {
    this.state = null;
    this.county = null;
    this.township = null;
    this.mobileFilter();
  }

}
